import { useState } from "react";
import { Link } from "react-router-dom";
import FormIcon from "../../../Assets/Images/account_84x84.svg";

const CheckProducerForm = () => {
    const [email, setEmail] = useState("");
    const [result, setResult] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [attempt, setAttempt] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    // Basic email validation regex
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAttempt(true);
        setFormErrors(null);

        const trimmedEmail = email.trim();

        // Validate the email format before making the API call
        if (!validateEmail(trimmedEmail)) {
            setFormErrors("Please enter a valid email address.");
            setSubmitting(false);
            return;
        }

        setSubmitting(true);

        try {
            // Fetch with GET request using the email as a query parameter
            const response = await fetch(`/AuthenticateUser/CheckProducer?email=${encodeURIComponent(email)}`);
            const data = await response.json();

            setResult({
                producerExists: data.producerExists,
                externalId: data.externalId
            });

            setSubmitting(false);
        } catch (error) {
            console.error("Error checking producer:", error);
            setFormErrors("Error occurred while checking producer.");
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="grid xs-form-body">
                <div className="flex-basis-10">
                    <Link className="white-xs-pill-btn" to="/ProducerPortal">Go Back</Link>
                </div>
                <div className="pt-4 flex-center">
                    <div className="flex-center" style={{ maxWidth: "84px", maxHeight: "84px" }}>
                        <img className="flex-center" alt="form icon" src={FormIcon} />
                    </div>
                </div>
                <div>
                    <label className="header-login flex-center">Check Producer</label>
                </div>
                <div className="pb-2">
                    Email Address<span className="required-asterisk"></span><br />
                    <input
                        required
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        maxLength="256"
                        className="email-login-form-input pt-4"
                        autoComplete="off"
                        style={{ textTransform: "none", padding: "22.5px 14px", height: "1.4375em" }}
                    />
                </div><br />
                {formErrors && (
                    <div className="alert alert-danger flex-center">{formErrors}</div>
                )}
                <div className="flex-center">
                    <button className="primary-lg-pill-btn" onClick={handleSubmit} disabled={submitting}>
                        {submitting ? "Checking..." : "Check Producer"}
                    </button>
                </div><br />
                <div className="flex-center"><Link to="/ProducerLogin">Cancel</Link></div>

                {attempt && result !== null && (
                    <div className="flex-center">
                        {!result.producerExists ? (
                            <div className="alert alert-danger">Producer does not exist.</div>
                        ) : result.producerExists && !result.externalId ? (
                            <div className="alert alert-warning">Producer exists but they do not have Commercial Auto Access.</div>
                        ) : (
                            <div className="alert alert-success">
                                Producer exists, the producer has Commercial Auto Access.<br />
                                User ID: {result.externalId}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default CheckProducerForm;
